import TextArea from "antd/es/input/TextArea";

function OutputTextArea(props) {
  return (
    <TextArea
      style={{height: '100%', resize: 'none'}}
      readOnly
      value={props.outputText}
    />
  )
}

export default OutputTextArea;