import './App.css';
import {Button, Col, ConfigProvider, Row, Spin} from "antd";
import {Component} from "react";
import OutputTextArea from "./OutputTextArea";
import TextArea from "antd/es/input/TextArea";

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputText: "",
      outputText: "",
      isFormatting: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      inputText: event.target.value
    });
  };

  // Cannot make "this" pointer work in this handler, thus adding "app" param, use "app" instead of "this".
  handleReformatClick = async (app) => {
    if (app.state.isFormatting) {
      return;
    }
    app.setState({
      isFormatting: true,
      outputText: "",
    })

    const apiUrl = 'https://stream-api.formatters.org';

    try {

      const body = JSON.stringify({"input": app.state.inputText});

      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      })

      const reader = response.body.getReader();

      const decoder = new TextDecoder();
      while (true) {
        const {value, done} = await reader.read();
        if (done) break;
        app.setState(prevState => ({
          outputText: prevState.outputText + decoder.decode(value),
        }))
      }


    } catch (error) {
      console.error('Error formatting log:', error);
    } finally {
      app.setState({
        isFormatting: false,
      });
    }
  }

  render() {
    const input = (
      <TextArea
        style={{height: '100%', resize: 'none'}}
        placeholder="Paste your logs here"
        value={this.state.inputValue}
        onChange={this.handleInputChange}
      />
    );

    const button = (
      <Button type="primary" onClick={() => this.handleReformatClick(this)} disabled={this.state.isFormatting}>
        {/*Reformat*/}
        {this.state.isFormatting ? <Spin/> : 'Reformat'}
      </Button>
    );

    return (
      <>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#83a78d',
            },
          }}
        >
          <div id="container"
               style={{
                 paddingLeft: "24px",
                 paddingRight: "24px",
                 paddingBottom: "12px",
                 height: "100vh"
               }}>
            <h1 style={{marginBottom: '16px', fontSize: '24px'}}>
              AI-Powered Log Formatter
            </h1>
            <Row justify="space-between" align="middle" style={{height: "100%"}}  gutter = {[22, 2]}>
              <Col flex={1} style={{height: "100%"}}>
                {input}
              </Col>
              <Col flex="10px" align="center">
                {button}
              </Col>
              <Col flex={1} style={{height: "100%"}}>
                <OutputTextArea outputText={this.state.outputText}/>
              </Col>
            </Row>
          </div>
        </ConfigProvider>
      </>
    );
  }

}

export default App;
